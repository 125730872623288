import React from "react"
import { Row, Col, Container, ListGroup, Card, Navbar } from "react-bootstrap"

// import Custom/ from "../components/layout"
import Layout from "../components/layout"
import LinkItem from "../components/linkItem"
import SEO from "../components/seo"
import { graphql } from 'gatsby';
import Img from 'gatsby-image'

const IndexPage = (props) => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
      {/* <CustomNavbar/> */}
    <div className="cards">
      <LinkItem title="2020" link="./2020" image="preview2020.jpg">
        {/* <Img fluid={props.data.preview2020.childImageSharp.fluid} /> */}
        {/* --2020-3-- */}
        </LinkItem>
      {/* <LinkItem>--2020-3--</LinkItem>
      <LinkItem>--2020-3--</LinkItem>
      <LinkItem>--2020-3--</LinkItem> */}

    </div>
    <Container className="text-center cards">
      {/* <Card>
        test
      </Card> */}
      <Row>

      </Row>
    </Container>
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    preview2020: file(relativePath: { eq: "preview2020.jpg" }) {
      ...fluidImage
    }
    preview2019: file(relativePath: { eq: "preview2020.jpg" }) {
      ...fluidImage
    }
  }
`